import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/vincepicone/Programming/Work/carbon-website/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnchorLinks = makeShortcode("AnchorLinks");
const AnchorLink = makeShortcode("AnchorLink");
const Row = makeShortcode("Row");
const Column = makeShortcode("Column");
const Caption = makeShortcode("Caption");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h3 {...{
      "id": "date-and-time-pickers-allow-users-to-select-a-single-or-a-range-of-dates-and-times"
    }}>{`Date and time pickers allow users to select a single or a range of dates and times.`}</h3>
    <AnchorLinks mdxType="AnchorLinks">
      <AnchorLink mdxType="AnchorLink">Variations</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Content</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Interaction</AnchorLink>
      <AnchorLink mdxType="AnchorLink">Time picker</AnchorLink>
    </AnchorLinks>
    <h2 {...{
      "id": "variations"
    }}>{`Variations`}</h2>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Purpose`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Range date picker`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`To select a range of dates, accompanied by a calendar widget.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Single date picker`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When a user needs to select one date, accompanied by a calendar widget.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><em parentName="td">{`Simple date picker`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`When the date is known by the user and they don’t need a calendar to anticipate the dates.`}</td>
        </tr>
      </tbody>
    </table>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <span {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "1152px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "183.42391304347825%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAlCAYAAABCr8kFAAAACXBIWXMAAAsSAAALEgHS3X78AAAFD0lEQVRIx61WTUhjVxR+acyL2Mq0/mOV0e6KunEhxUUX2o2bwbZQhS6Li2opozBQpqAbfxBXzrSFUildFEHa6QhGmnYcsNMWKlQzOv7k3/xpXt5vXv5/3svtufeZYDRWU3rgcE5u7v3uueec+71LOZ1O6ipxuVyU3W6n8JxS6nA4iOYx/g0Li44qQzBwwenq6iL+8PBw9dDQUN3IyMite3dHX0WIoTOizZjgbMZcTqU5jqOPjo6MVqvVCNHQsJYGa8iDEYvDnJmZIQM/m826n1ZNL1n+WIPoamgY0p8Lgj4f9ebmJuV2u3UAoisCzMvExAT1jQsRv2kK6Z/tMg/2HMyTA7ewarELP7oDwiM2FDSFQqG1YDD4fTwer8Nzs9lscf6w09fXR/zndpbYr8wS7Q2GbQgkksgiRoghVgijdDqFhxDP8xmWZdvxXEhDIcJLRZEEDTDoOaTDkrCNAeSwlA74fYrX61EEQcxAZCgcDnMnJye38VyI+OrisSyn5cLppnlesCSTSbxYkWU5BzYny2E1FoshURQFAGy7FhDCJ9bj8dCCIFhSqSSSRFGRJAmDYFVxhPC7PEC7CwPylkRKQYIUAUARg2BVo9Fo+RHifoOJFhwNFEABRTCGfRWOjn0BKn1zwNPTU9zEBBAsAYSqIkiDiiMFX2AYpu0GRdGqHAgEMOA2Ph6AZECVs0gzOJfwHwcR3i7nyEYAJ32YTqcRNC9R7GMB0DScor0kYFNTE9XY2Ej89fV1Yg8ODgyQ9IcAugFRrcEG62dqAjXD+Ap0QgOe6/f7S0fY0NBAlSvQk0U3pHCX82D19fUVdXV1lQ2NTfSddz+oery+aYjnkN7LpvQBIav381n9qajoPWxGv2lFpelrf3+f6u3tJf74+N2q0bGx1z6//1n1e+90VW2MUToE6y7r+zrGZ6VtdifQmMNQRLAIaTvV1tYW7fjF8u9vfLe69eaTF6nXzZZIi9kSbfn1ebxlYy/eatqON9//IUWobfmZSF0ih5qaGmIfLv9J7MDXyPC3lXu6Z/NHd60BxsvEWF8ozoINeYKyBKxk//a3VAue+2grrruS/j0nPLFPt5w0w4X3sjmgKimCfAEGeXynQGEySqZVxPKybDvWGtvt567uQ4HXGtvjstGSKOwQ+pLDWZ5jVY4NqeAriXgMGEjiQ0HtLvMscz19ebw+muOBHBIJTAjn2EZSI9E4IYdAQANkmBvclONjjW2SqTQGOQOUUCQsqEj5D3xocxwX6Esk9CWhaERCdr+s/rKbBBYXBX+gDECez9NXjNAXywkoLrPo8V+yeudBDskiK3gDzM0BgUlouK8WTPdgFRjPgc2xHK8ynEZfwWAZgLAAAxL6glxlgFmAujCFcdmwpNEXcxP6yvMhABTRVyaTQYqiEIsFcpqGjdq1Kl9om6WlJaq5uZn4CwsL+VeB3ufzDcGiT+H4H4OOwiZEwf8Exj+Cm1GtdcQxVfLl0NbWVjZ9RSKRgl+4eiaTiWptbSV+Z2dnZUdHR3V3d3fV4ODgrZWVFRpOaIDvsxHSVgn5MkKUFRBhBQCQdw22RXwIrylqYGCADMzNzRlnZ2dfWVxcrJqenn55Z2en8mJUsNiIX13n1FDyyD09PSWPBQBvwcQx+FB9CFV/+9r34eHhIdXf30/8qakpanJykpqfn8ffCsJ3cM3G4aiHUM0X0ORfnhVCB0UresXe5AX7/wt+X1+UPMNfJf8AqWzZ0d7+PIUAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "range, single, and simple date pickers",
            "title": "range, single, and simple date pickers",
            "src": "/static/a113bde3a8c41d1900a57317de96cafd/fb070/date-picker-usage-1.png",
            "srcSet": ["/static/a113bde3a8c41d1900a57317de96cafd/d6747/date-picker-usage-1.png 288w", "/static/a113bde3a8c41d1900a57317de96cafd/09548/date-picker-usage-1.png 576w", "/static/a113bde3a8c41d1900a57317de96cafd/fb070/date-picker-usage-1.png 1152w", "/static/a113bde3a8c41d1900a57317de96cafd/c3e47/date-picker-usage-1.png 1472w"],
            "sizes": "(max-width: 1152px) 100vw, 1152px",
            "loading": "lazy"
          }}></img>{`
    `}</span>
        <Caption mdxType="Caption">Types of date pickers</Caption>
      </Column>
    </Row>
    <h2 {...{
      "id": "content"
    }}>{`Content`}</h2>
    <h4 {...{
      "id": "labels"
    }}>{`Labels`}</h4>
    <p>{`Both date and time pickers are accompanied by labels, and follow the same accessibility guidelines for all `}<a parentName="p" {...{
        "href": "/components/form"
      }}>{`forms`}</a>{`.`}</p>
    <h4 {...{
      "id": "format"
    }}>{`Format`}</h4>
    <p>{`For date pickers, use placeholder text so users input the date in the correct format. It can be formatted in a variety of ways. See the date picker code `}<a parentName="p" {...{
        "href": "https://github.com/carbon-design-system/carbon/blob/master/packages/components/src/components/date-picker/README.md"
      }}>{`documentation`}</a>{` for more info.`}</p>
    <h2 {...{
      "id": "interaction"
    }}>{`Interaction`}</h2>
    <h4 {...{
      "id": "calendar-widget"
    }}>{`Calendar widget`}</h4>
    <p>{`It is recommended to use the date picker with a calendar widget when the user is browsing between a range of dates. Aid the user in making the proper choices by providing a visual reference of dates to choose from. The calendar widget appears once the user has interacted with the date input field (typically on `}<inlineCode parentName="p">{`:focus`}</inlineCode>{`).`}</p>
    <Row mdxType="Row">
      <Column colLg={8} mdxType="Column">
        <img {...{
          "src": "/a0c6cd407391b2a80723d32f93dcae8e/date-picker-usage-animation-1.gif",
          "alt": "example of date picker"
        }}></img>
        <Caption mdxType="Caption">Selecting dates from the range date picker</Caption>
      </Column>
    </Row>
    <h4 {...{
      "id": "simple-date-picker"
    }}>{`Simple date picker`}</h4>
    <p>{`The simple date picker provides the user with a text input in which they can input month/day/year. Simple date pickers are typically used when the date is known by the user, such as a birthday or credit card expiration.`}</p>
    <h2 {...{
      "id": "time-picker"
    }}>{`Time picker`}</h2>
    <p>{`Time pickers provide the user with a text input in which they can input hours/minutes. Additionally, they can be accompanied by an “AM/PM” selection and a time zone selection, which is styled as an `}<a parentName="p" {...{
        "href": "/components/select"
      }}>{`inline select`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      